import { AsyncState } from 'state-domains/types';
import { DataRowType } from 'src/components/DataTable';
import { CONFIGURATION_TYPES } from 'state-domains/constants';

import { MXDTimestamp } from '../utils';
import {
    Account,
    Activities,
    ActivityUser,
    ActivityUsers,
    Columns,
    EvoConfigurationState,
    EvoDiscoveryState,
    EvoOrgState,
    FileObject,
    GridEntry,
    GroupUsers,
    Header,
    HeaderType,
    MetaData,
    Operations,
    Profile,
    Projection,
    ProjectUsers,
    Rows,
    UserPermissions,
    UsersByType,
    WorkspaceEvoState,
} from '..';

export interface ConfigurationState extends AsyncState {
    listsState: ConfigurationListState;
    tablesState: ConfigurationTableState;
    activitiesState: ConfigurationActivitiesState;
    activityGroupsState: ConfigurationReducedState;
    gridsState: ConfigurationGridState;
    rankedColumnsState: ConfigurationRankedColumnsState;
    headerFieldTypesState: ConfigurationHeaderFieldTypeState;
    headersState: ConfigurationHeaderState;
    categoriesState: ConfigurationReducedState;
    configurationDataGrid: AsyncState;
    modifyColumnState: AsyncState & { column?: any };
    offset?: number;
    limit?: number;
    sampleWorkflow: ConfigurationLoggingState;
    usersState: ConfigurationReducedState;
    [index: string]: any;
    defaultLabServicesState: ConfigurationDefaultLabServicesState;
    evoState: {
        orgState: AsyncState & { orgData: EvoOrgState };
        workspaceState: AsyncState & { workspaces: WorkspaceEvoState[] };
        configurationState: AsyncState & { projectData: EvoConfigurationState };
        discoveryState: AsyncState & { discovery: EvoDiscoveryState };
    };
}

export interface ConfigurationLoggingState extends AsyncState {
    items: any[];
    deleteState: AsyncState;
    cloneState: { id: string } & AsyncState;
    addState: { id: string } & AsyncState;
    editState: AsyncState & { result?: any };
    exportState: { url: string } & AsyncState;
}

export interface ConfigurationReducedState {
    items: any[];
    shouldReload: boolean;
    loadingState: AsyncState;
    operationState: AsyncState;
}

export interface ConfigurationHeaderState extends ConfigurationLoggingState {
    focusId: string;
    addFieldState: AsyncState;
}

export interface ConfigurationHeaderFieldTypeState extends ConfigurationLoggingState {
    importState: {
        errors: { line: number; message: string }[];
        infos: { line: number; message: string }[];
        fields: string | null;
        success: boolean;
    } & AsyncState;
    shouldReload: boolean;
}
export interface ConfigurationListState extends ConfigurationLoggingState {
    importState: {
        imports: {
            id?: string;
            verifiedWarnings?: boolean;
            errors: { line: number; message: string }[];
            infos: { line: number; message: string }[] | BulkValidateNewColumns[];
            list: string | null;
            success: boolean;
        }[];
    } & AsyncState;
    fileState: {
        groupId: string;
        files: Record<string, FileObject>;
    } & AsyncState;
    emptyFileGroupState: Record<string, boolean>;
}
export interface ConfigurationTableState extends ConfigurationLoggingState {
    importState: {
        errors: any;
        infos: { line: number; message: string }[];
        success: string[];
        tables: ConfigurationTableData | null;
        tableViews: ConfigurationTableData | null;
        tableCategories: ConfigurationTableData | null;
    } & AsyncState;
}

export interface ConfigurationActivitiesState extends ConfigurationLoggingState {
    activitiesState: {
        activities: string | null;
    } & AsyncState;
}

export interface ConfigurationRankedColumnsState extends AsyncState {
    columns: SampleResultsColumn[];
    candidateColumnsMap: Record<string, SampleResultsColumn>;
    rankedColumnsMap: Record<string, RankedColumnType>;
}

export interface ConfigurationProjectsState extends ConfigurationLoggingState {
    projectsState: {
        projects: string | null;
    } & AsyncState;
    coordinateState: {
        convertedCoords: any;
    } & AsyncState;
    projectActivitiesState: { activity: string } & AsyncState;
    showCoordsInDecimal: boolean;
    projectUserPermissionState: { userPermissions: UserPermissions[] } & AsyncState;
}

export interface ConfigurationDefaultLabServicesState extends AsyncState {
    labServices: DefaultLabServiceCollections;
}

export interface ConfigurationGridState extends ConfigurationLoggingState {
    projectionState: { projections: Record<string, Projection> } & AsyncState;
    previewState: { data: GridPreviewData | object } & AsyncState;
    shouldReload: boolean;
}

export interface ConfigurationTableData {
    added: number;
    failed: number;
    items: string[];
}

export interface ConfigurationList {
    isSample: boolean;
    ismx: boolean;
    id: string;
    name: string;
    description: string;
    category?: string;
    categoryId?: string;
    error?: string;
    updatedAt: MXDTimestamp;
    updatedBy: string;
    createdAt: MXDTimestamp;
    createdBy: string;
    result: string;
    rows: any;
    usedIn?: any;
}

export interface ConfigurationActivityGroup {
    id: string;
    name: string;
    updatedAt: MXDTimestamp;
    updatedBy: string;
    createdAt: MXDTimestamp;
    createdBy: string;
    usedIn?: any;
}

export interface ConfigurationProject {
    id: string;
    name: string;
    description: string;
    updatedAt: MXDTimestamp;
    updatedBy: string;
    createdAt: MXDTimestamp;
    createdBy: string;
    boundary: any;
    activities: Activities;
    users: ProjectUsers;
}

export interface ConfigurationLists {
    List: ConfigurationList[];
}

export interface ConfigurationActivities {
    Activity: ConfigurationList[];
}

export interface ConfigurationProjects {
    Project: ConfigurationProject[];
}

export interface ConfigurationResponse<T> {
    items: T[];
}

export type ConfigurationTablesResponse = ConfigurationResponse<any>;
export type ConfigurationHeadersResponse = ConfigurationResponse<any>;
export type ConfigurationFieldsResponse = ConfigurationResponse<any>;
export type ConfigurationActivitiesResponse = ConfigurationResponse<any>;

export interface ConfigurationError {
    error: Error;
}

export interface ConfigurationListResponse {
    id: string;
    name: string;
    description?: string;
    category: string;
    rows: Rows;
    columns: Columns;
    primaryColumn: string;
    inuseColumn: string;
    ismx: boolean;
    isvalid: boolean;
    usedIn: ConfigurationUsedIn;
    published: boolean;
    isSample: boolean;
    parentList: null;
    createdBy: string;
    createdAt: MXDTimestamp;
    updatedBy: string;
    updatedAt: MXDTimestamp;
}
export interface ConfigurationHeaderFieldTypesResponse {
    HeaderFieldType: HeaderType[];
}

export type ConfigurationHeaderResponse = ConfigurationListResponse;
export interface ConfigurationUsedIn {
    drillingHeaders: any;
    pointHeaders: any;
    drillingTables: any;
    drillingActivities: any;
    pointActivities: any;
}

export interface ConfigurationAddSectionHeaderResponse {
    newSectionId: string;
    header: Header;
}

export interface DeleteListRowsPayload {
    listId: string;
    rowIds: string[];
}

export interface ConfigurationCreateListRequest {
    category: string; // id
    _templateId: string; // used for clone
    parent: string; // used for clone
    isDirty: boolean;
    untouched: boolean;
    name: string;
    description: string;
    published: boolean;
}

export type ConfigurationCreateProjectRequest = ConfigurationCreateListRequest;

export interface ConfigurationProjectActivityRequest {
    activity: string;
    index: number;
    users: any;
    id: string;
    type?: string;
}

export interface ConfigurationCreateActivityRequest {
    category: string;
    _templateId: string;
    parent?: string;
    isDirty?: boolean;
    untouched?: boolean;
    name: string;
    type: string;
    description?: string;
    surveyNegativeDipIsDown?: boolean;
    defaultTargetDepth?: number | string;
}

export interface ConfigurationCreateWorkflowRequest {
    name: string;
    description: string;
    category: number;
    isDirty: boolean;
    parent: string;
    untouched: boolean;
    _templateId: string;
}

export interface ConfigurationCreateHeaderRequest {
    category: string;
    _templateId: string;
    parent: string;
    isDirty: boolean;
    untouched: boolean;
    name: string;
    description: string;
    published: boolean;
    type: string;
    isvalid: boolean;
    ismx: boolean;
    usedIn: any;
    createdBy: string;
    updatedBy: string;
    id?: string;
}

export interface BulkValidateImportList {
    errors: BulkValidateErrors[];
    newColumns: BulkValidateNewColumns[];
}

export interface BulkValidateErrors {
    line: number;
    message: string;
}

export interface BulkValidateNewColumns {
    name: string;
    type: string;
}

export interface SampleResultsColumn {
    methodDetail: string;
    code: string;
    analyte: string;
    analyteId?: string;
    codeName: string;
    codeId?: string;
    laboratory: string;
    serviceSchedule: string;
    analyteName: string;
    unitName: string;
    unitId?: string;
    unit?: string;
    label: string;
    labLabel: string;
    id?: string;
}

export interface PossibleSampleResultsColumnsResponse {
    columns: SampleResultsColumn[];
}

export interface RankedColumnType {
    name: string;
    unit: string;
    unitId: string;
    candidateColumns: SampleResultsColumn[] | string[];
    computeStatus?: string;
    activity?: string;
    createdBy?: string;
    createdAt?: MXDTimestamp;
    updatedBy?: string;
    updatedAt?: MXDTimestamp;
    id?: string;
}

export enum ASSOCIATE_COLUMN_ACTION_TYPE {
    ADD = 'add',
    REMOVE = 'remove',
}

export interface AssignUnassignResponse {
    user: ActivityUser;
    type: AssignUnassignObjectType;
    payloadType: AssignUnassignPayloadType;
    projectId: string;
    activityId?: string;
    groupId?: string;
}
export type AssignUnassignPayloadType = 'assign' | 'unassign' | 'unassign-remove';
export type AssignUnassignObjectType = 'project' | 'activity' | 'group';
export interface UnassignPayload {
    payloadType: AssignUnassignPayloadType;
    type: AssignUnassignObjectType;
    projectId: string;
    activityId?: string;
    groupId?: string;
    userId: string;
    user?: ActivityUser;
}
export interface AssignPayload {
    payloadType: AssignUnassignPayloadType;
    type: AssignUnassignObjectType;
    projectId: string;
    activityId?: string;
    groupId?: string;
    user: ActivityUser;
}

export interface EditConfigurationProjectActivity {
    activity: string;
    groups: Record<string, { name: string; users: GroupUsers; id: string }>;
    id: string;
    index: number;
    users: ActivityUsers;
    type?: string;
}

export interface ListFileGroup {
    id: string;
    list: string;
    row: string;
    column: string;
    files: Record<string, FileObject>;
    createdBy?: string;
    createdAt?: MXDTimestamp;
    updatedBy?: string;
    updatedAt?: MXDTimestamp;
}

export interface DefaultLabService extends MetaData {
    id: string;
    lab: string;
    serviceSchedule: string;
    code: string;
    laboratoryUrl: string;
    webAccess: string;
    webAccessUrl: string;
    serviceArea: string;
    serviceRegion: string;
    category: string;
    type: string;
    application: string;
    serviceTypes: string;
    sampleDecomposition: string;
    analyticalMethod: string;
}

export type DefaultLabServiceCollection = Record<string, DefaultLabService>;

export type DefaultLabServiceCollections = Record<string, DefaultLabServiceCollection>;

export type DefaultLabServicesResponse = Record<string, Record<string, Record<string, any>>>;

export interface GridPreviewResponse {
    PreviewGrid: GridPreviewData;
}

export interface GridPreviewData {
    center: any[][];
    centerVector: any[][];
    gridLines: any[][];
}

export interface AssignCoordinatesPayload {
    payloadType: Operations;
    gridId?: string;
    referenceExisting?: string;
    coordinateData?: Partial<GridEntry>;
}
export interface UserItem {
    userId: string;
    subscriptionId: string;
    role: string | string[];
    profile: Profile;
    email: string;
    type: string;
    isXidAdmin: boolean;
    serial: string;
    crossShifter: string;
    frequency: string;
    expires: MXDTimestamp;
    createdAt: MXDTimestamp;
}

export interface LoadAccountInformationResponse {
    account: Account;
    users: UsersByType;
}

export enum CATEGORY_TYPES {
    ACTIVITY = 'Activity',
    DISPATCH_HEADER = 'Dispatch Header',
    HEADER = 'Header',
    LIST = 'List',
    TABLE = 'Table',
}

export interface ConfigurationCategory extends MetaData {
    id: string;
    name: string;
    ismx: boolean;
    categoryType: CATEGORY_TYPES;
    usedIn?: any;
    type?: string;
}

export enum ModalType {
    DELETE,
    REMOVE,
    CLONE,
    EDIT,
    CREATE,
    IMPORT,
    IMPORT_BULK,
    EXPORT,
    ADD,
}

export interface ActionModalProps {
    actionItem: DataRowType | null;
    configType: CONFIGURATION_TYPES;
    modal?: ModalType;
    open: boolean;
    handleClose: () => void;
    updateModalBusy?: (val: boolean) => void;
}
